import React from "react"
import { Helmet } from "react-helmet"

const SEO = () => {
  return (
    <div>
      <Helmet>
        <title>Inovett - Accelerating Innovation</title>
        <meta
          name="description"
          content="Inovett - We augment your people, processes, products or things to build an intelligent enterprise"
        />
        <meta
          name="og:title"
          property="og:title"
          content="Inovett - Accelerating Innovation"
        ></meta>
        <meta
          name="twitter:card"
          content="Inovett - Accelerating Innovation"
        ></meta>
        <link rel="canonical" href="https://inovett.co.uk"></link>
        <meta property="og:image" content="/og-image.png" />
      </Helmet>
    </div>
  )
}

export default SEO

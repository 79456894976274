import React from 'react'
import {Link} from 'gatsby'
import logo from "../../assets/images/footer-logo.png"

const Footer = () => {

    const currentYear = new Date().getFullYear();

    return (
        <footer className="footer-area bg-color">
            <div className="container">
                <div className="single-footer-widget text-center">
                    <h2>What's Next?</h2>
                    <p className="mx-auto mb-md-5">If you have any questions or would like to discuss your
                    specific business needs, our team are on hand to help.</p>
                    <Link to="/contact" className="default-btn">
                        Contact Us
                        <span></span>
                    </Link>
                    <br/>
                    <a href="/" className="logo mt-5">
                        <img src={logo} alt="logo" />
                    </a>
                    <ul className="social-link">
                        <li>
                            <Link to="#" className="d-block rounded-circle" target="_blank" rel="noreferrer">
                                <i className='bx bxl-facebook'></i>
                            </Link>
                        </li>
                        <li>
                            <Link to="#" className="d-block rounded-circle" target="_blank" rel="noreferrer">
                                <i className='bx bxl-twitter'></i>
                            </Link>
                        </li>
                        <li>
                            <Link to="#" className="d-block rounded-circle" target="_blank" rel="noreferrer">
                                <i className='bx bxl-instagram '></i>
                            </Link>
                        </li>
                        <li>
                            <Link to="#" className="d-block rounded-circle" target="_blank" rel="noreferrer">
                                <i className='bx bxl-linkedin'></i>
                            </Link>
                        </li>
                    </ul>

                    <ul className="footer-links-list pt-3 pt-md-5 mx-auto w-50 d-flex justify-content-around flex-column flex-md-row">
                        <li>
                            <Link to="/">
                                Privacy Policy
                            </Link>
                        </li>
                        {/* <li>
                            <Link to="/">
                                Terms of use
                            </Link>
                        </li> */}
                    </ul>
                </div>
            </div>
            <div className="footer-bottom-area text-center">
                <p>©{currentYear} Inovett.</p>
            </div>
        </footer>
    );
}

export default Footer;